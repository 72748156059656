import React, { useState, useEffect, useRef, useMemo } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Box,
  Container,
  IconButton,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import { alpha } from '@mui/material/styles';
import { keyframes } from '@emotion/react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { useNavigate, useLocation } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DescriptionIcon from '@mui/icons-material/Description';
import PeopleIcon from '@mui/icons-material/People';
import Slider from '@mui/material/Slider';
import TextField from '@mui/material/TextField';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import { Link as ScrollLink } from 'react-scroll';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.background,
  boxShadow: 'none',
}));

const Logo = styled('img')({
  height: 40,
  marginRight: 16,
});

const NavButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontWeight: 400,
  fontSize: '1.1rem',
  fontFamily: 'Manrope, sans-serif',
  borderRadius: '20px', // Added this line
}));

const AuthButton = styled(Button)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  fontWeight: 400,
  fontSize: '1.1rem',
  color: theme.palette.text.secondary,
  '&.MuiButton-contained': {
    color: theme.palette.primary.contrastText,
  },
  borderRadius: '15px',
}));

const LandingPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [mobileOpen, setMobileOpen] = useState(false);
  const [, setCurrentWord] = useState(0);
  const words = useMemo(() => ['screen', 'vet', 'qualify', 'engage', 'source'], []);
  const [, setActiveStep] = useState(0);
  const stepsRef = useRef([]);
  const navigate = useNavigate();
  const location = useLocation();
  const isSpecialRoute = location.pathname === '/357831';
  const calLink = isSpecialRoute 
    ? 'https://cal.com/team/hirepluto/product-demo-357831'
    : 'https://cal.com/team/hirepluto/product-demo';

  const [displayedWord, setDisplayedWord] = useState('');
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const typingSpeed = 250;
  const deletingSpeed = 200;
  const pauseBetweenWords = 1500;

  // Define testimonials at the beginning of the component
  const testimonials = [
    {
      testimonial: "That was awesome.. that's my first time doing AI interview- it was smooth and less stressful."
    },
    {
      testimonial: "I loved this Interview process"
    },
    {
      testimonial: "I LOVEDDDDDDDDD THIS!!"
    },
    {
      testimonial: "Felt like I was communicating with a real person. Awesome experience."
    },
    {
      testimonial: "Absolutely fantastic voice detection software. Usually apps have trouble with my accent."
    },
    {
      testimonial: "I honestly felt like I was in a real interview while doing this. It is very impressive what you have built thus far. Coming from a position in Which I had to interview potential new hires, I would have loved to have had access to this tool."
    }
  ];

  useEffect(() => {
    if (isMobile) {
      // On mobile, rotate through words instantly
      const rotateWords = () => {
        setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
        setDisplayedWord(words[currentWordIndex]);
      };

      const timer = setInterval(rotateWords, 3000); // Change word every 3 seconds

      return () => clearInterval(timer);
    } else {
      // On desktop, use the typing animation
      let timer;

      const typeWord = () => {
        const currentWord = words[currentWordIndex];

        if (!isDeleting && displayedWord !== currentWord) {
          setDisplayedWord(currentWord.substring(0, displayedWord.length + 1));
        } else if (isDeleting && displayedWord !== '') {
          setDisplayedWord(currentWord.substring(0, displayedWord.length - 1));
        } else if (isDeleting && displayedWord === '') {
          setIsDeleting(false);
          setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
        } else {
          setIsDeleting(true);
          timer = setTimeout(typeWord, pauseBetweenWords);
          return;
        }

        timer = setTimeout(typeWord, isDeleting ? deletingSpeed : typingSpeed);
      };

      timer = setTimeout(typeWord, typingSpeed);

      return () => clearTimeout(timer);
    }
  }, [displayedWord, isDeleting, currentWordIndex, isMobile, words]); // Added 'words' to the dependency array

  const handleSignIn = () => {
    navigate('/signin'); // or '/login', depending on your route
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    const observers = [];
    stepsRef.current.forEach((step, index) => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            setActiveStep(index);
          }
        },
        { threshold: 0.5 }
      );
      observer.observe(step);
      observers.push(observer);
    });

    return () => {
      observers.forEach(observer => observer.disconnect());
    };
  }, []);

  useEffect(() => {
    const wordInterval = setInterval(() => {
      setCurrentWord((prevWord) => (prevWord + 1) % words.length);
    }, 3000); // Change word every 3 seconds

    return () => clearInterval(wordInterval);
  }, [words.length]); // Added 'words.length' to the dependency array

  const navItems = [];

  const drawer = (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        backgroundColor: theme.palette.background.paper,
        py: 4, // Add some padding at the top and bottom
      }}
      onClick={handleDrawerToggle}
    >
      <List sx={{ width: '100%', maxWidth: 360 }}>
        {navItems.map((item) => (
          <ListItem key={item.label} disablePadding>
            <ScrollLink
              to={item.target}
              smooth={true}
              duration={500}
              offset={-64}
              onClick={handleDrawerToggle}
              style={{ width: '100%' }}
            >
              <ListItemText
                primary={item.label}
                primaryTypographyProps={{
                  fontFamily: 'Manrope, sans-serif',
                  fontWeight: 400,
                  fontSize: '1.25rem',
                  color: theme.palette.text.primary,
                  textAlign: 'center',
                }}
              />
            </ScrollLink>
          </ListItem>
        ))}
        <ListItem disablePadding>
          <Button
            fullWidth
            variant="outlined"
            color="primary"
            onClick={() => {
              handleSignIn();
              handleDrawerToggle();
            }}
            sx={{
              py: 1.5,
              borderRadius: '20px',
              mt: 2,
              borderColor: theme.palette.primary.main,
              '&:hover': {
                borderColor: theme.palette.primary.dark,
              },
            }}
          >
            <ListItemText
              primary="Sign In"
              primaryTypographyProps={{
                fontFamily: 'Manrope, sans-serif',
                fontWeight: 400,
                fontSize: '1.25rem',
                color: theme.palette.text.primary,
                textAlign: 'center',
              }}
            />
          </Button>
        </ListItem>
        <ListItem disablePadding>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            href={calLink}
            target="_blank"
            rel="noopener noreferrer"
            onClick={handleDrawerToggle}
            sx={{ py: 1.5, borderRadius: '20px', mt: 2 }}
          >
            <ListItemText
              primary="Get Started"
              primaryTypographyProps={{
                fontFamily: 'Manrope, sans-serif',
                fontWeight: 400,
                fontSize: '1.25rem',
                color: theme.palette.primary.contrastText,
                textAlign: 'center',
              }}
            />
          </Button>
        </ListItem>
      </List>
    </Box>
  );

  const partnerLogos = [
    { alt: 'ATX Logo', src: '/atx.png' },
    { alt: 'Overture Games', src: '/overture_games_logo.png' },
    { alt: 'Element Recruitment', src: '/element_recruitment_logo.png' },
    { alt: 'Sine Systems Logo', src: '/sine_systems.png' },
    { alt: 'EarthTech Talent', src: '/erthtech_talent_logo.png' }
  ];

  const logoSlideAnimation = keyframes`
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-50%);
    }
  `;

  const slideAnimation = keyframes`
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-50%);
    }
  `;

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const faqData = [
    {
      question: "Can Pluto integrate with my ATS?",
      answer: "Yes, Pluto seamlessly connects with most major ATS's but can also operate independently if you prefer.",
    },
    {
      question: "How does Pluto prevent cheating?",
      answer: "Pluto employs advanced techniques to ensure candidate authenticity throughout the conversation. We'd be happy to discuss this in more detail over a call."
    },
    {
      question: "How does Pluto remove bias?",
      answer: "Pluto uses recruiter input and unbiased models to provide objective, skills-based assessments, promoting a more diverse workforce."
    },
    {
      question: "What languages does Pluto support?",
      answer: "Pluto supports multiple languages to accommodate a diverse candidate pool."
    }
  ];

  const [recruiters, setRecruiters] = useState(5);
  const [callsPerWeek, setCallsPerWeek] = useState(20);
  const [timePerCall, setTimePerCall] = useState(30);

  const totalMinutesSaved = recruiters * callsPerWeek * timePerCall;
  const totalHoursSaved = Math.round(totalMinutesSaved / 60);

  return (
    <Box sx={{ flexGrow: 1, bgcolor: 'background.default' }}>
      <StyledAppBar position="static">
        <Container maxWidth="lg">
          <Toolbar disableGutters>
            {/* Logo column */}
            <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-start' }}>
              <Logo src="/logo.png" alt="Company Logo" />
            </Box>

            {/* Navigation column */}
            {!isMobile && (
              <Box sx={{
                flex: 2,
                display: 'flex',
                justifyContent: 'center',
                gap: theme.spacing(3)
              }}>
                {navItems.map((item) => (
                  <ScrollLink
                    key={item.label}
                    to={item.target}
                    smooth={true}
                    duration={500}
                    offset={-100} // Adjust this value as needed
                    spy={true}
                    activeClass="active"
                  >
                    <NavButton>{item.label}</NavButton>
                  </ScrollLink>
                ))}
              </Box>
            )}

            {/* Auth buttons column */}
            <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
              {isMobile ? (
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="end"
                  onClick={handleDrawerToggle}
                >
                  <MenuIcon />
                </IconButton>
              ) : (
                <>
                  <AuthButton variant="outlined" color="primary" onClick={handleSignIn}>Sign In</AuthButton>
                  <AuthButton
                    variant="contained"
                    color="primary"
                    href={calLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Get Started
                  </AuthButton>
                </>
              )}
            </Box>
          </Toolbar>
        </Container>
      </StyledAppBar>
      <SwipeableDrawer
        anchor="top"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        onOpen={handleDrawerToggle}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
        PaperProps={{
          sx: {
            maxHeight: '80%', // Limit the height to 80% of the viewport
            overflowY: 'auto', // Add scrolling if content exceeds the height
          },
        }}
      >
        {drawer}
      </SwipeableDrawer>

      {/* Updated main content section */}
      <Container maxWidth="lg" sx={{ mt: { xs: 6, sm: 8, md: 16 }, mb: 8, px: 3 }}> {/* Increased mt from 8 to 12 */}
        <Typography
          variant="h2"
          component="h1"
          align="center"
          sx={{
            fontWeight: 'normal',
            color: theme.palette.text.primary,
            mb: 0, // Changed from 2 to 0
            fontSize: { xs: '2.5rem', sm: '3rem', md: '3.75rem' },
          }}
        >
          Hire <span style={{ color: theme.palette.glowingGreen.main }}>Pluto</span>.
        </Typography>
        <Typography
          variant="h2"
          component="h2"
          align="center"
          sx={{
            fontWeight: 'normal',
            color: theme.palette.text.primary,
            mt: 0, // Added to ensure no top margin
            mb: 2,
            fontSize: { xs: '2.5rem', sm: '3rem', md: '3.75rem' },
          }}
        >
          <Box component="span" display={{ xs: 'block', sm: 'inline' }}>Your recruiter</Box>{' '}
          <Box component="span" display={{ xs: 'block', sm: 'inline' }}>
            copilot to <span style={{ color: theme.palette.glowingGreen.main }}>{displayedWord}</span>.
          </Box>
        </Typography>
        <Typography
          variant="body1"
          align="center"
          sx={{
            color: theme.palette.text.secondary,
            mb: 4,
            fontSize: { xs: '1.125rem', sm: '1.25rem', md: '1.35rem' },
            maxWidth: '600px',
            margin: '0 auto',
          }}
        >
          Effortless hiring with Pluto—the most natural-sounding Voice AI assistant for recruiters.
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4, mb: 6 }}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            sx={{
              fontWeight: 400,
              fontSize: '1.1rem',
              padding: '12px 24px',
              borderRadius: '20px', // Added this line
            }}
            href={calLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            Book a Demo
          </Button>
        </Box>

        <Box
          sx={{
            width: '100%',
            height: 'auto', // Changed from fixed height to auto
            backgroundColor: '#f0f0f0',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '8px',
            overflow: 'hidden',
            mb: 4,
          }}
        >
          <img
            src="/call.png"
            alt="Pluto Call Illustration"
            style={{
              width: '100%',
              height: 'auto', // Changed from 100% to auto
              maxHeight: '600px', // Added max-height to prevent excessive stretching on large screens
              objectFit: 'contain', // Changed from 'cover' to 'contain'
            }}
          />
        </Box>

        {/* Updated company logos section with carousel */}
        <Box sx={{ mb: 6 }}>
          <Typography
            variant="subtitle1"
            component="h3"
            align="center"
            sx={{
              color: theme.palette.text.secondary,
              mb: 3,
              fontWeight: 'medium',
              fontSize: '1rem',
            }}
          >
            Helping recruiters at
          </Typography>
          <Box
            sx={{
              position: 'relative',
              overflow: 'hidden',
              height: '80px',
              width: '100%',
              margin: '0 auto',
              '&::after': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background: `linear-gradient(to right, ${theme.palette.background.default}, transparent 10%, transparent 90%, ${theme.palette.background.default})`,
                pointerEvents: 'none',
                zIndex: 1,
              }
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                position: 'absolute',
                animation: `${logoSlideAnimation} 30s linear infinite`,
                height: '100%',
                width: 'max-content',
              }}
            >
              {[...partnerLogos, ...partnerLogos].map((logo, index) => (
                <Box
                  key={index}
                  sx={{
                    height: '80px',
                    width: '200px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginRight: '40px',
                    flexShrink: 0,
                  }}
                >
                  <img
                    src={logo.src}
                    alt={logo.alt}
                    style={{
                      maxHeight: '100%',
                      maxWidth: '100%',
                      objectFit: 'contain',
                      filter: 'grayscale(100%)',
                    }}
                  />
                </Box>
              ))}
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            mt: 4,
            mb: 4,
            backgroundColor: 'white',
            width: '100vw',
            position: 'relative',
            left: '50%',
            right: '50%',
            marginLeft: '-50vw',
            marginRight: '-50vw',
            py: 6,
          }}
        >
          <Container maxWidth="lg" sx={{ px: { xs: 3, sm: 4, md: 5 } }}>
            <Typography
              variant="h4"
              component="h3"
              sx={{
                color: 'black',
                fontWeight: 'normal',
                mb: 4, // Increased from 1 to 4 for more spacing
              }}
            >
              Why hiring is broken
            </Typography>

            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}>
              {/* Right half - 4 sections (now on the left) */}
              <Box sx={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                mr: { md: 4 },
                mb: { xs: 4, md: 0 },
              }}>
                {[
                  {
                    icon: <SearchIcon sx={{ color: 'black', fontSize: '2rem' }} />,
                    title: "Application Overload",
                    subtext: "Too many unqualified or uninterested applicants flood your inbox."
                  },
                  {
                    icon: <DescriptionIcon sx={{ color: 'black', fontSize: '2rem' }} />,
                    title: "Resumes Aren't Enough",
                    subtext: "Resumes only scratch the surface, missing soft skills and cultural fit."
                  },
                  {
                    icon: <AccessTimeIcon sx={{ color: 'black', fontSize: '2rem' }} />,
                    title: "Time Drained on Screening",
                    subtext: "Endless initial calls eat up your valuable time."
                  },
                  {
                    icon: <PeopleIcon sx={{ color: 'black', fontSize: '2rem' }} />,
                    title: "Subconscious Bias",
                    subtext: "Hidden biases can influence hiring decisions, affecting diversity."
                  }
                ].map((item, index) => (
                  <Box key={index} sx={{ mb: index < 3 ? 3 : 0 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 0.5 }}>
                      {item.icon}
                      <Typography variant="h6" sx={{ fontWeight: 'normal', ml: 1, fontSize: '1.3rem' }}>
                        {item.title}
                      </Typography>
                    </Box>
                    <Typography variant="body1" color="text.secondary" sx={{ fontSize: '1.1rem' }}>
                      {item.subtext}
                    </Typography>
                  </Box>
                ))}
              </Box>

              {/* Left half - Cost Savings Calculator (now on the right) */}
              <Box sx={{
                flex: 1,
                backgroundColor: alpha(theme.palette.glowingGreen.main, 0.1),
                borderRadius: '8px',
                p: 3,
              }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                  <Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                      <Typography>Number of Recruiters</Typography>
                      <TextField
                        type="number"
                        value={recruiters}
                        onChange={(e) => setRecruiters(Math.max(1, Math.min(100, parseInt(e.target.value) || 1)))}
                        InputProps={{
                          inputProps: { min: 1, max: 100 },
                          sx: { width: '80px', textAlign: 'right' }
                        }}
                        variant="standard"
                      />
                    </Box>
                    <Slider
                      value={recruiters}
                      onChange={(_, newValue) => setRecruiters(newValue)}
                      min={1}
                      max={100}
                    />
                  </Box>

                  <Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                      <Typography>Weekly Calls per Recruiter</Typography>
                      <TextField
                        type="number"
                        value={callsPerWeek}
                        onChange={(e) => setCallsPerWeek(Math.max(1, Math.min(100, parseInt(e.target.value) || 1)))}
                        InputProps={{
                          inputProps: { min: 1, max: 100 },
                          sx: { width: '80px', textAlign: 'right' }
                        }}
                        variant="standard"
                      />
                    </Box>
                    <Slider
                      value={callsPerWeek}
                      onChange={(_, newValue) => setCallsPerWeek(newValue)}
                      min={1}
                      max={100}
                    />
                  </Box>

                  <Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                      <Typography>Time per Call (minutes)</Typography>
                      <TextField
                        type="number"
                        value={timePerCall}
                        onChange={(e) => setTimePerCall(Math.max(1, Math.min(120, parseInt(e.target.value) || 1)))}
                        InputProps={{
                          inputProps: { min: 1, max: 120 },
                          sx: { width: '80px', textAlign: 'right' }
                        }}
                        variant="standard"
                      />
                    </Box>
                    <Slider
                      value={timePerCall}
                      onChange={(_, newValue) => setTimePerCall(newValue)}
                      min={1}
                      max={120}
                    />
                  </Box>
                </Box>

                <Box sx={{ mt: 3, p: 2, backgroundColor: 'white', borderRadius: '8px' }}>
                  <Typography variant="h6" sx={{ color: 'black', fontWeight: 'normal', mb: 1 }}>
                    Time Spent per Week
                  </Typography>
                  <Typography variant="h4" sx={{ color: theme.palette.glowingGreen.main, fontWeight: 'bold' }}>
                    {totalHoursSaved} hours
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Container>
        </Box>

        {/* Add an id to the "How Pluto enables recruitment" section */}
        <Box
          id="product-section"
          sx={{
            borderRadius: '8px',
            p: 3,
            mt: 4,
            backgroundColor: theme.palette.background.default,
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 3 }}>
            <Box sx={{ maxWidth: { xs: '100%', md: '50%' } }}>
              <Typography
                variant="h4"
                component="h3"
                sx={{
                  color: 'black',
                  fontWeight: 'normal',
                  mb: 2,
                }}
              >
                How Pluto enables recruitment
              </Typography>

              <Typography
                variant="subtitle1"
                sx={{
                  color: theme.palette.text.secondary,
                  mb: 3,
                  fontSize: '1.1rem',
                }}
              >
                An all-in-one recruiter copilot that handles the heavy lifting while you make the decisions.
              </Typography>
            </Box>
          </Box>

          {/* Stacked boxes */}
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
          }}>
            {[
              {
                title: 'Voice AI',
                image: '/voice.gif',
                keyPoints: [
                  "Engage candidates with the most human-like AI interactions available. Available 24/7 on mobile or desktop, Pluto instantly creates detailed candidate profiles from each conversation."
                ]
              },
              {
                title: 'Vetting AI',
                image: '/vetting.png',
                keyPoints: [
                  "Tailor your recruitment process with custom questions and domain expertise. Pluto provides unbiased assessments to help you identify and focus on the best talent."
                ]
              },
              {
                title: 'Transcription AI',
                image: '/connections.png',
                keyPoints: [
                  "Keep your hiring organized with centralized notes. Pluto enhances candidate profiles by combining resumes with comprehensive conversation transcripts."
                ]
              },
              {
                title: 'Search AI (coming soon)',
                image: '/search.png',
                keyPoints: [
                  "Rediscover past applicants who match new roles with our holistic search. Pluto goes beyond keywords to find candidates that best fit your team's needs."
                ],
                largeImage: true
              }
            ].map((item, index) => (
              <Box
                key={index}
                sx={{
                  borderRadius: '8px',
                  display: 'flex',
                  overflow: 'hidden',
                  backgroundColor: theme.palette.background.default,
                  flexDirection: { xs: 'column', md: index % 2 === 1 ? 'row' : 'row-reverse' },
                }}
              >
                <Box
                  sx={{
                    flex: 1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    p: 2,
                    height: { xs: '300px', md: item.largeImage ? '500px' : '400px' },
                    overflow: 'hidden',
                    order: { xs: 1, md: 'unset' },
                  }}
                >
                  <img
                    src={item.image}
                    alt={`${item.title} illustration`}
                    style={{
                      maxWidth: '100%',
                      maxHeight: '100%',
                      objectFit: 'contain',
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    flex: 1,
                    p: 3,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    order: { xs: 2, md: 'unset' },
                  }}
                >
                  <Typography variant="h6" color="text.primary" sx={{ fontWeight: 'normal', fontSize: '1.3rem', mb: 1 }}>
                    {item.title}
                  </Typography>
                  {item.keyPoints && (
                    <Box sx={{ mt: 2 }}>
                      {item.keyPoints.map((point, i) => (
                        <Typography
                          key={i}
                          variant="body1"
                          color="text.secondary"
                          sx={{
                            mb: 1.5,
                            fontSize: '1.1rem',
                          }}
                        >
                          {point}
                        </Typography>
                      ))}
                    </Box>
                  )}
                </Box>
              </Box>
            ))}
          </Box>
        </Box>

        <Box
          sx={{
            mt: 4,
            mb: 4,
            backgroundColor: 'white',
            width: '100vw',
            position: 'relative',
            left: '50%',
            right: '50%',
            marginLeft: '-50vw',
            marginRight: '-50vw',
            py: 6,
          }}
        >
          <Container maxWidth="lg" sx={{ px: { xs: 3, sm: 4, md: 5 } }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
              <Box sx={{ maxWidth: '70%', textAlign: 'center' }}>
                <Typography
                  variant="h4"
                  component="h3"
                  sx={{
                    color: 'black',
                    fontWeight: 'normal',
                    mb: 1,
                  }}
                >
                  What candidates say about Pluto
                </Typography>

                <Typography
                  variant="subtitle1"
                  sx={{
                    color: theme.palette.text.secondary,
                  }}
                >
                  Real, unedited experiences from candidates who've connected with Pluto
                </Typography>
              </Box>
            </Box>

            {/* Testimonial Carousel */}
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
              <Box
                sx={{
                  position: 'relative',
                  overflow: 'hidden',
                  height: '250px', // Increased height
                  width: '100%',
                  margin: '0 auto',
                  mb: 4,
                  '&::after': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    background: 'linear-gradient(to right, white, transparent 10%, transparent 90%, white)',
                    pointerEvents: 'none',
                    zIndex: 1,
                  }
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    position: 'absolute',
                    animation: `${slideAnimation} 30s linear infinite`,
                    height: '100%',
                    width: 'max-content',
                  }}
                >
                  {[...testimonials, ...testimonials].map((testimonial, index) => (
                    <Box
                      key={index}
                      sx={{
                        width: '400px', // Increased width
                        height: '100%',
                        padding: '30px', // Increased padding
                        boxSizing: 'border-box',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: '#171717', // Changed to black
                        color: 'white', // Changed to white
                        borderRadius: '12px', // Slightly increased border radius
                        marginRight: '30px', // Increased margin
                        flexShrink: 0,
                      }}
                    >
                      <Typography variant="h6" align="center">
                        &quot;{testimonial.testimonial}&quot;
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Box>

              {/* Updated star rating and text */}
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  {[...Array(4)].map((_, index) => (
                    <StarIcon key={index} sx={{ color: theme.palette.warning.main }} />
                  ))}
                  <StarHalfIcon sx={{ color: theme.palette.warning.main }} />
                </Box>
                <Typography
                  variant="subtitle1"
                  sx={{
                    color: theme.palette.text.secondary,
                    textAlign: 'center',
                  }}
                >
                  Average user satisfaction score of 4.8/5
                </Typography>
              </Box>
            </Box>

            {/* Mobile Testimonial Cards */}
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
              <Swiper
                spaceBetween={30}
                slidesPerView={1}
                pagination={{
                  clickable: true,
                  dynamicBullets: true,
                }}
                modules={[Pagination]}
                breakpoints={{
                  640: {
                    slidesPerView: 2,
                  },
                  1024: {
                    slidesPerView: 3,
                  },
                }}
                style={{ paddingBottom: '40px' }} // Add padding to the bottom of the Swiper
              >
                {testimonials.map((testimonial, index) => (
                  <SwiperSlide key={index}>
                    <Box
                      sx={{
                        height: '250px',
                        padding: '20px',
                        boxSizing: 'border-box',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'black',
                        color: 'white',
                        borderRadius: '12px',
                      }}
                    >
                      <Typography variant="h6" align="center">
                        "{testimonial.testimonial}"
                      </Typography>
                    </Box>
                  </SwiperSlide>
                ))}
              </Swiper>
            </Box>
          </Container>
        </Box>

        <Box
          sx={{
            borderRadius: '8px',
            p: 3,
            mt: 4,
            backgroundColor: theme.palette.background.default,
          }}
        >
          <Typography
            variant="h4"
            component="h3"
            sx={{
              color: 'black',
              fontWeight: 'normal',
              mb: 1,
            }}
          >
            Frequently Asked Questions
          </Typography>

          <Typography
            variant="subtitle1"
            sx={{
              color: theme.palette.text.secondary,
              mb: 3,
            }}
          >
            Common questions about Pluto and how it can help your recruitment process
          </Typography>

          {faqData.map((faq, index) => (
            <Accordion
              key={index}
              expanded={expanded === `panel${index}`}
              onChange={handleChange(`panel${index}`)}
              sx={{
                '&:not(:last-child)': { mb: 2 },
                '&:before': { display: 'none' },
                boxShadow: 'none',
                backgroundColor: alpha(theme.palette.background.paper, 0.6),
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index}bh-content`}
                id={`panel${index}bh-header`}
                sx={{
                  backgroundColor: 'transparent',
                  '& .MuiAccordionSummary-content': {
                    margin: '12px 0',
                  },
                }}
              >
                <Typography sx={{ fontWeight: 'normal' }}>
                  {faq.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  {faq.answer}
                  {faq.link && (
                    <Link href={faq.link} target="_blank" rel="noopener noreferrer">
                      {faq.linkText}
                    </Link>
                  )}
                  {faq.additionalText}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>

        {/* Updated "Let's chat" section */}
        <Box
          sx={{
            backgroundColor: theme.palette.glowingGreen.main,
            borderRadius: '8px',
            minHeight: { xs: '300px', sm: '400px' }, // Reduced height for mobile
            mt: 8,
            mb: 8,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: { xs: 3, sm: 4 }, // Added padding, more on mobile
          }}
        >
          <Typography
            variant="h3"
            component="h2"
            align="center"
            sx={{
              color: 'white',
              fontWeight: 'normal',
              mb: 2,
              fontSize: { xs: '2rem', sm: '3rem' }, // Smaller font on mobile
            }}
          >
            We're here for you
          </Typography>
          <Typography
            variant="h6"
            component="p"
            align="center"
            sx={{
              color: 'white',
              mb: 4,
              fontSize: { xs: '1rem', sm: '1.25rem' }, // Smaller font on mobile
            }}
          >
            Literally we're available almost anytime.
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' }, // Stack buttons on mobile
              justifyContent: 'center',
              alignItems: 'center',
              gap: 2,
              width: '100%', // Full width on mobile
            }}
          >
            <Button
              variant="contained"
              color="primary"
              size="large"
              fullWidth // Full width on mobile
              sx={{
                fontWeight: 400,
                backgroundColor: 'white',
                color: theme.palette.glowingGreen.main,
                padding: '12px 24px',
                '&:hover': {
                  backgroundColor: alpha(theme.palette.common.white, 0.9),
                },
                borderRadius: '20px',
                maxWidth: { sm: '200px' }, // Limit width on larger screens
              }}
              href={calLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              Book a Demo
            </Button>
            <Button
              variant="outlined"
              color="primary"
              size="large"
              fullWidth // Full width on mobile
              startIcon={<YouTubeIcon />}
              sx={{
                fontWeight: 400,
                borderColor: 'white',
                color: 'white',
                padding: '12px 24px',
                '&:hover': {
                  borderColor: alpha(theme.palette.common.white, 0.9),
                  backgroundColor: alpha(theme.palette.common.white, 0.1),
                },
                borderRadius: '20px',
                maxWidth: { sm: '200px' }, // Limit width on larger screens
              }}
              href="https://www.loom.com/share/52d4ea6722ef4e509722b23fde1b8bb3?sid=20703ddc-17bf-405b-931c-70a14ea8d1d2"
              target="_blank"
              rel="noopener noreferrer"
            >
              View 5 min demo
            </Button>
          </Box>
        </Box>
      </Container>

      {/* Footer */}
      <Box
        component="footer"
        sx={{
          py: 6,
          px: 2,
          mt: 'auto',
          backgroundColor: theme.palette.background.default,
          borderTop: `1px solid ${theme.palette.divider}`,
        }}
      >
        <Container maxWidth="lg">
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              flexDirection: { xs: 'column', sm: 'row' },
            }}
          >
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: { xs: 'center', sm: 'flex-start' },
              mb: { xs: 4, sm: 0 }
            }}>
              <img src="/logo.png" alt="HirePluto Logo" style={{ height: 50, marginBottom: 16 }} />
              <Typography variant="body1" color="text.secondary">
                Pluto Laboratory, Inc. │ All Rights Reserved 2024
              </Typography>
            </Box>
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: { xs: 'center', sm: 'flex-end' },
              mt: { xs: 2, sm: 0 }
            }}>
              <img src="/gdpr.avif" alt="GDPR Compliance" style={{ height: 80, marginBottom: 16 }} />
              <Box>
                <Link href="/terms-of-service" color="inherit" sx={{ mr: 3, fontSize: '1.1rem' }}>
                  Terms of Service
                </Link>
                <Link href="/privacy-policy" color="inherit" sx={{ fontSize: '1.1rem' }}>
                  Privacy Policy
                </Link>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default LandingPage;
