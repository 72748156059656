import React, { useState, useEffect } from 'react';
import { Route, Routes, Navigate, useParams } from 'react-router-dom';
import Layout from './Layout';
import JobListingsPage from './JobListingsPage';
import AddJobPage from './AddJobPage';
import SignInPage from './SignInPage';
import AccountsPage from './AccountsPage';
import ProtectedRoute from './ProtectedRoute';
import OrganizationsPage from './OrganizationsPage';
import JobApplicationPage from './JobApplicationPage';
import ApplicationCompletePage from './ApplicationCompletePage';
import PaymentsPage from './PaymentsPage';
import NewUserWelcome from './NewUserWelcome';
import CandidateDashboard from './CandidateDashboard';
import { useAuth } from './AuthContext';
import TermsOfService from './TermsOfService.js';
import PrivacyPolicy from './PrivacyPolicy';
import JobSelectionPage from './JobSelectionPage';
import LandingPage from './LandingPage';
import { useAuthRedirect } from './hooks/useAuthRedirect';
import SharePage from './SharePage';
import ExperiencePage from './ExperiencePage';
import ExperienceVideoPage from './ExperienceVideoPage';

// This component will handle the redirection for the /tryme route
const TryMeRedirect = () => {
  useEffect(() => {
    window.location.href = 'https://cal.com/forms/6f114dfe-f6ea-4e55-92bc-6e51f4e488d6';
  }, []);

  return null;
};

// This component will handle the redirection for the /scanme route
const ScanMeRedirect = () => {
  return <Navigate to="/" replace />;
};

// This component will handle the conditional rendering for the apply route
const ApplyRoute = () => {
  const { jobId } = useParams();
  return <Navigate to={`/apply/new_user/${jobId}`} replace />;
};


function AppContent() {
  useAuthRedirect();

  const { userRole } = useAuth();
  const [selectedCandidateId, setSelectedCandidateId] = useState(null);
  const [show, setShow] = useState(true);
  const handleCloseCandidateDetails = () => {
    console.log('Closing candidate details');
    setSelectedCandidateId(0);
    setShow(false);
  };


  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/357831" element={<LandingPage />} />
      <Route path="/terms-of-service" element={<TermsOfService />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/signin" element={<SignInPage />} />
      <Route path="/apply/new_user/:jobId" element={<NewUserWelcome />} />
      <Route path="/application-complete" element={<ApplicationCompletePage />} />
      <Route path="/apply/:jobId" element={<ApplyRoute />} />
      <Route path="/tryme" element={<TryMeRedirect />} />
      <Route path="/share/:encodedShare" element={<SharePage />} />
      <Route path="/experience" element={<ExperiencePage />} />
      <Route path="/experience/:id" element={<ExperienceVideoPage />} />
      <Route path="/scanme" element={<ScanMeRedirect />} />

      <Route element={<ProtectedRoute />}>
        {userRole === 'candidate' ? (
          <Route path="/dashboard" element={<CandidateDashboard />} />
        ) : (
          <>

            <Route element={<Layout selectedCandidateId={selectedCandidateId} handleCloseCandidateDetails={handleCloseCandidateDetails} />}>
              <Route path="/dashboard" element={<JobSelectionPage />} />
              <Route path="/job/:jobId" element={<JobListingsPage show={show} setShow={setShow} />} />
              <Route path="/payments" element={<PaymentsPage />} />
              <Route path="/add-job" element={<AddJobPage />} />
              <Route path="/organizations" element={<OrganizationsPage />} />
              <Route path="/account" element={<AccountsPage />} />
              <Route path="/application/:jobId" element={<JobApplicationPage />} />
            </Route>
          </>
        )}
      </Route>
    </Routes>
  );
}

export default AppContent;
